<template lang="pug">
section.main
  //- aside.side-menu(v-if="$auth.loggedIn")
  aside.side-menu
    side-menu
  main.core
    section.section.brand-section
      //- active-team
      tab-menu(:tabs="items")
      div.columns.is-centered
        div.column.is-four-fifths-fullhd
          .panel.p-6
            slot
</template>

<script>
import SideMenu from "~/components/Sidemenu";
import TabMenu from "~/components/Tabmenu";
import ActiveTeam from "~/components/ActiveTeam";

export default {
  components: {
    SideMenu,
    TabMenu,
    ActiveTeam,
  },
  data() {
    return {
      items: [
        {
          label: "Brand Colors",
          link: "/brand/colors",
        },
        {
          label: "Brand Fonts",
          link: "/brand/fonts",
        },
        // {
        //   label: 'Assets',
        //   link: '/brand/assets',
        // },
      ],
      activeItem: 1,
    };
  },
};
</script>
<style lang="scss">
section.main {
  overflow-x: hidden;
  display: flex;
  width: 100%;
  .side-menu {
    // flex: 1;
    width: rempx(91);
  }
  .core {
    flex: 1;
  }
}
</style>
